.dashboardContainer {
  max-width: 1600px !important;
}


.enhanced-textarea::-webkit-scrollbar {
  width: 8px;
  border-radius: 0%;
}

.enhanced-textarea::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
  border-radius: 0%;
}

.enhanced-textarea::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
