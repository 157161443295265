@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  ol {
    list-style-type: decimal;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
  li {
    margin-bottom: 0.25rem;
    font-size: 14px;
  }
  li:last-child {
    margin-bottom: 0rem;
  }
  p {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  div {
    font-size: 14px;
  }
  textarea {
    outline: none !important;
  }
  textarea:focus {
    outline: none !important;
  }
}
